import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
// logo
import rockLogo from '../../../assets/rock.png';
// import rockLogo from '../../../assets/rockLogo.png';
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import { handleLogout } from "../../../hooks/useLogout";
import navReportConfig from "./reportConfig";
//
import navConfig from "./config";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));


// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
        // backgroundColor: '#1c2434',
        backgroundColor: '#133337',
        // color: 'black'
        color: 'white'
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
        {/* <Logo /> */}
        <img src={rockLogo} alt="logo" height={'100px'} width={'auto'} />
      </Box>


      <NavSection data={navReportConfig} name={'Report'} />
      {/* <NavSection data={navConfig} name={'Manage'} />   */}

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: "relative" }}
        >
          <Button
            onClick={() => handleLogout(navigate)}
            variant="outlined"
            sx={{
              backgroundColor: "#5db438", '&:hover': {
                backgroundColor: '#7FD858', // Change the color for hover state
              }, color: 'white', display: 'flex', alignItems: 'center', gap: '10px'
            }}
            fullWidth
          >
            <Logout />
            Logout
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
      style={{ backgroundColor: "red" }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
