import { useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Button, Container, Grid, Typography, useTheme, Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Select,
} from "@mui/material";
import { filter } from "lodash";
import Scrollbar from "../../components/scrollbar";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";

function descendingComparator(a, b, orderBy) {
  const valueA = Number.isNaN(Number(a[orderBy])) ? a[orderBy]?.toUpperCase() : parseFloat(a[orderBy]);
  const valueB = Number.isNaN(Number(b[orderBy])) ? b[orderBy]?.toUpperCase() : parseFloat(b[orderBy]);

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(0,0,0, 0.08)',
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function applySortFilter(array, comparator, query) {
  // console.log(array);
  const stabilizedThis = array ? array?.map((el, index) => [el, index]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SlipTable({ owner, loader }) {
  const TABLE_HEAD = [
    // { id: "id", label: "Id", alignRight: false },
    { id: "", label: "Shop", alignRight: false },
    { id: "name", label: "Cashier", alignRight: false },
    //  { id: "name", label: "Shop Owner", alignRight: false },
    { id: "stake", label: "Stake", alignRight: false },
    { id: "gameType", label: "Game Type", alignRight: false },
    { id: "gameNumber", label: "Game Number", alignRight: false },
    { id: "numberPick", label: "Picked Numbers", alignRight: false },
    { id: "netWinning", label: "Net winning", alignRight: false },
    { id: "id", label: "Ticket number", alignRight: false },
    { id: "date", label: "Date", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    // { id: "status", label: "Status", alignRight: false },
  ];

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = owner.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - owner.length) : 0;

  const filteredUsers = applySortFilter(
    owner,
    getComparator(order, orderBy),
    filterName
  );
  const isNotFound = !filteredUsers.length;

  return (
    <>
      <Card style={{ margin: '1rem 0' }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, margin: '1rem 0' }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={owner.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {
                  filteredUsers && filteredUsers
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      // console.log(row);
                      const {
                        id,
                        shop,
                        game,
                        cashier,
                        gameType,
                        totalStake,
                        netWinning,
                        toWinMax,
                        toWinMin,
                        numberPick,
                        slipType,
                        company,
                        cashierId,
                        shopId,
                        shopOwnerId,
                        status,
                      } = row;

                      return (
                        <StyledTableRow
                          hover
                          key={id}
                        >
                          <TableCell>
                            {(index + 1)}
                          </TableCell>

                          <TableCell align="left">{shop.name}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {cashier.name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{totalStake || 0.00} birr</TableCell>
                          <TableCell align="left">{gameType || 0.00}</TableCell>
                          <TableCell align="left">{game.gameNumber || 0}</TableCell>
                          <TableCell align="left">
                            {(numberPick) && JSON.parse(numberPick).map((pick, index) => (
                              <span key={index}>
                                {pick.selection && Array.isArray(pick.selection)
                                  ? pick.selection.join(', ')
                                  : pick.selection}
                                {pick.val && Array.isArray(pick.val)
                                  ? pick.val.join(', ')
                                  : pick.val}
                                {index < JSON.parse(numberPick).length - 1 && ' | '}
                              </span>
                            ))}</TableCell>
                          <TableCell align="left">{netWinning || 0.00} birr</TableCell>
                          <TableCell align="left">{id || '-'} </TableCell>
                          <TableCell align="left">{row.created_at?.substring(0, 10) || 0.00}</TableCell>
                          <TableCell align="left">{status || 0}</TableCell>

                        </StyledTableRow>
                      );
                    })
                }
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No ticket we're found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={owner.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
};
