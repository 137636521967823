import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/styles";
import '../../styles/filter.scss';

import {
  Typography, useTheme, Card,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Select,
} from "@mui/material";
import Scrollbar from "../../components/scrollbar";
import { AppWidgetSummary } from "../../sections/@dashboard/app";
import AppWidgetNetBalance from "../../sections/@dashboard/app/AppWidgetNetBalance";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
// mock
import { extendCashierLimit, fetchShopStat } from "../../data/fetchShopOwner";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";
import RetailListToolbar from "./RetailListToolbar";

function descendingComparator(a, b, orderBy) {
  const valueA = Number.isNaN(Number(a[orderBy])) ? a[orderBy]?.toUpperCase() : Math.abs(parseFloat(a[orderBy]));
  const valueB = Number.isNaN(Number(b[orderBy])) ? b[orderBy]?.toUpperCase() : Math.abs(parseFloat(b[orderBy]));

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


const StyledTableRow = styled(TableRow)(({ theme, totalNetBalance }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: parseInt(totalNetBalance, 10) < 0 ? 'rgba(255, 0, 0, 0.15)' : 'rgba(0,0,0, 0.08)',
    // Use red color with opacity if totalNetBalance is less than 0, otherwise use the default color
  },
  '&:nth-of-type(odd)': {
    backgroundColor: parseInt(totalNetBalance, 10) < 0 ? 'rgba(255, 0, 0, 0.15)' : 'rgba(0,0,0, 0.01)',
    // Use red color with opacity if totalNetBalance is less than 0, otherwise use the default color
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function applySortFilter(array, comparator, query) {
  // console.log(array);
  const stabilizedThis = array ? array?.map((el, index) => [el, index]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => {
      const shopNameMatch = _user?.shop?.name?.toLowerCase().includes(query.toLowerCase()) || _user?.shopOwner?.name?.toLowerCase().includes(query.toLowerCase()) || _user?.cashier?.name?.toLowerCase().includes(query.toLowerCase());
      // Add more variables as needed

      // Return true only if both shopNameMatch and otherVariableMatch are true
      return shopNameMatch /* && moreVariableMatches */;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RetailTable({ owner, loader, cash, filterData, handleFetchData }) {

  const commonHeaders = [
    // { id: "id", label: "Id", alignRight: false },
    { id: "", label: "Shop", alignRight: false },
    cash ? { id: "name", label: "Cashier", alignRight: false } : { id: "name", label: "Shop Owner", alignRight: false },
    { id: "totalTickets", label: "Tickets", alignRight: false },
    { id: "totalStake", label: "Stakes", alignRight: false },
    { id: "totalPayout", label: "Payout amount", alignRight: false },
  ];
  const conditionalHeaders3 = cash ? [
    { id: "totalPayoutCount", label: "Payout count", alignRight: false },
    { id: "totalUnclaimed", label: "Unclamed amount", alignRight: false },
  ] : [
    { id: "totalPayoutCount", label: "Payout count", alignRight: false },
    { id: "totalUnclaimed", label: "Unclamed amount", alignRight: false },
  ]
  const conditionalHeaders2 = cash ? [
    { id: "totalUnclaimedCount", label: "Unclamed Count", alignRight: false },
  ] : [
    { id: "totalUnclaimedCount", label: "Unclamed Count", alignRight: false },
  ]
  const commonHeaders2 = [
    { id: "totalRevoked", label: "Revoked amount", alignRight: false },
    // { id: "margin", label: "Margin", alignRight: false },
    { id: "totalGGR", label: "GGR", alignRight: false },
    {
      id: "totalNetBalance", label: "Net balance", alignRight: false,
      format: (value) => parseInt(value, 10).toLocaleString('en-US'),
    }
  ]

  const TABLE_HEAD = [...commonHeaders, ...conditionalHeaders3, ...conditionalHeaders2, ...commonHeaders2];

  const { data, dispatch } = useContext(CampaignContext);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("totalNetBalance");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [limitLoader, setLimitLoader] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = owner.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - owner.length) : 0;

  const filteredUsers = applySortFilter(
    owner,
    getComparator(order, orderBy),
    filterName
  );

  useEffect(() => {
    setPage(0);
  }, [order, owner])
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>

      <Card sx={{ margin: '1rem 0' }}>
        <div spacing={5} className="statCards" >
          <div className="cards" style={{ padding: '0' }}>
            <AppWidgetSummary
              title="All Bets"
              total={filteredUsers && `${filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalStake, 10), 0)} birr` || '0'}
              bottom={filteredUsers && `${filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalTickets, 10), 0)} tickets` || '0 tickets'}
              // color="info"
              postfix='birr'
              icon={"ant-design:apple-filled"}
            />
          </div>
          <div className="cards">
            <AppWidgetSummary
              title="Payouts"
              total={filteredUsers && `${filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalPayout, 10), 0)} birr` || '0'}
              bottom={filteredUsers && `${filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalPayoutCount, 10), 0)} tickets` || '0 tickets'}
              // color="info"
              postfix='birr'
              icon={"ant-design:apple-filled"}
            />
          </div>
          <div className="cards">
            {
              cash ? (
                <AppWidgetSummary
                  title="Cashiers"
                  total={filteredUsers && filteredUsers.length || '0'}
                  // total={shopStat?.totalShop || '0'}
                  // color="info"
                  icon={"ant-design:apple-filled"}
                  bottom={`${filteredUsers && filteredUsers.filter((a) => a.active > 0).length || '0' || '0'} active cashiers`}
                // bottom={`${shopStat?.activeshops || '0'} active shops`}
                />
              ) : (
                <AppWidgetSummary
                  title="Shop"
                  total={filteredUsers && filteredUsers.length || '0'}
                  // total={shopStat?.totalShop || '0'}
                  // color="info"
                  icon={"ant-design:apple-filled"}
                  bottom={`${filteredUsers && filteredUsers.filter((a) => a.activeShops > 0).length || '0' || '0'} active shops`}
                // bottom={`${shopStat?.activeshops || '0'} active shops`}
                />
              )
            }
          </div>
          {/* <div className="cards">
            <AppWidgetSummary
              title="Margin"
              total={
                filteredUsers && (
                  (() => {
                    const ts = filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalStake, 10), 0)
                    const calculatedPercentage = ts ? (
                      (
                        filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalNetBalance, 10), 0) / ts
                      ).toFixed(2) * 100
                    ).toFixed(2) : 0;

                    return (calculatedPercentage || 0);
                  })()
                )
              }
              postfix='%'
              bottom={filteredUsers && ` .` || ' .'}
            // color="error"
            // icon={"ant-design:bug-filled"}
            />
          </div> */}
          <div className="cards">
            <AppWidgetNetBalance
              title="Net Balance"
              total={(filteredUsers && `${filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalNetBalance, 10), 0)} birr` || '0')}
              // bottom={filteredUsers && ` .` || ' .'}
              bottom={filteredUsers && `${(filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalTickets, 10), 0)) - filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalPayoutCount, 10), 0) - filteredUsers.reduce((acc, obj) => acc + parseInt(obj.totalUnclaimedCount, 10), 0)} tickets` || '0 tickets'}
              handleFetchData={handleFetchData}
              postfix='birr'
              color="#7FD858"
            // icon={"ant-design:android-filled"}
            />
          </div>
        </div>
      </Card>

      <Card sx={{ margin: '1rem 0' }}>

        <RetailListToolbar
          numSelected={selected.length}
          filterName={filterName}
          filterDate={filteredUsers}
          onFilterName={handleFilterByName}
          date={filterData}
          cash={cash}
        />

        {/* <Grid sx={{ margin: '1rem 0' }}>
        </Grid> */}

        {/* <Scrollbar> */}
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer >
            <Table stickyHeader>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={owner.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {
                  // loader ? (<div style={{ height: '50px' }}><CircularProgress /></div>) :
                  filteredUsers && filteredUsers
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        shop,
                        cashier,
                        totalTickets,
                        totalStake,
                        totalPayout,
                        totalPayoutCount,
                        totalUnclaimed,
                        cashierLimit,
                        totalUnclaimedCount,
                        totalRevoked,
                        totalRevokedCount,
                        totalNetBalance,
                        totalGGR
                      } = row;
                      const margin = ((totalNetBalance / totalStake) * 100).toFixed(2);

                      return (
                        <StyledTableRow
                          hover
                          key={id}
                          totalNetBalance={totalNetBalance}
                        // tabIndex={-1}
                        // role="checkbox"
                        // selected={selectedUser}
                        >
                          <TableCell>
                            {(index + 1)}
                          </TableCell>

                          <TableCell align="left">{shop.name}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {cash ? row.cashier?.username : row.shopOwner.name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{totalTickets || 0}</TableCell>
                          <TableCell align="left">{totalStake || 0.00} birr</TableCell>
                          <TableCell align="left">{totalPayout || 0.00} birr</TableCell>
                          <TableCell align="left">{totalPayoutCount || 0.00}</TableCell>
                          <TableCell align="left">{totalUnclaimed || 0.00} birr</TableCell>
                          <TableCell align="left">{totalUnclaimedCount || 0.00}</TableCell>
                          <TableCell align="left">{totalRevoked || 0} birr</TableCell>
                          {/* <TableCell align="left">
                            {totalStake !== '0.00' && totalStake !== 0
                              ? `${parseInt(margin, 10) < 0 ? '(' : ''}${Math.abs(margin)}${parseInt(margin, 10) < 0 ? ')' : ''}` : 0} %
                          </TableCell> */}

                          <TableCell align="left">{totalGGR || 0} birr</TableCell>
                          <TableCell align="left">{parseInt(totalNetBalance, 10) < 0 && '('} {Math.abs(totalNetBalance) || 0}{parseInt(totalNetBalance, 10) < 0 && ')'} birr</TableCell>

                          {/* <TableCell align="left">
                            
                                <Button variant="contained" color="primary" fullWidth onClick={() => extendCashierLimit(dispatch, setLoader, id, handleFetchData)}>withdraw</Button>
                                
                              </TableCell> */}
                        </StyledTableRow>
                      );
                    })
                }
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete
                          words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
        {/* </Scrollbar> */}

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          component="div"
          count={owner.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card >
    </>
  );
};
