import { useContext, useEffect, useState } from "react";
import { Box, Button, Chip, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Label } from "reactstrap";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

import { getShop, getShopReport, getSlipReport, getSubAgentShop } from "../../data/fetchShopOwner";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";
import SlipTable from "./SlipTable";
import '../../styles/filter.scss'
import { SearchButton } from "../../components/button/searchButton";

const names = [
  'Active',
  'Redeem',
  'Redeemed',
  'Cancelled',
];

const gameType = [
  'keno',
  'spin',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterTable = ({ filterData, setFilterData, handleFetchData, loader, shop }) => {
  const theme = useTheme();
  const [date, setDate] = useState('today');

  const handleDateChange = (selectedDate) => {
    let fromDate = null;
    let toDate = null;
    setDate(selectedDate)

    switch (selectedDate) {
      case 'today': {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = today;

        const endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = endOfDay;
        break;
      }
      case 'yesterday': {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = yesterday;

        const endOfDay = new Date(yesterday);
        endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = endOfDay;
        break;
      }
      case 'this_week': {
        const today = new Date();
        const firstDayOfWeek = new Date(today);
        const daysUntilMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
        firstDayOfWeek.setDate(today.getDate() - daysUntilMonday); // Set to the first day of the week (Monday)
        firstDayOfWeek.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfWeek;

        const lastDayOfWeek = new Date(today);
        const daysUntilSunday = 6 - daysUntilMonday;
        lastDayOfWeek.setDate(today.getDate() + daysUntilSunday); // Set to the last day of the week (Sunday)
        lastDayOfWeek.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfWeek;
        break;
      }
      case 'last_week': {
        const today = new Date();
        const firstDayOfLastWeek = new Date(today);
        const daysUntilMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
        firstDayOfLastWeek.setDate(today.getDate() - daysUntilMonday - 7); // Set to the first day of the previous week (Monday)
        firstDayOfLastWeek.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfLastWeek;

        const lastDayOfLastWeek = new Date(today);
        const daysUntilSunday = 6 - daysUntilMonday;
        lastDayOfLastWeek.setDate(today.getDate() + daysUntilSunday - 7); // Set to the last day of the previous week (Sunday)
        lastDayOfLastWeek.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfLastWeek;
        break;
      }
      case 'this_month': {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfMonth;

        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        lastDayOfMonth.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfMonth;
        break;
      }
      case 'last_month': {
        const today = new Date();
        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        firstDayOfLastMonth.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfLastMonth;

        const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        lastDayOfLastMonth.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfLastMonth;
        break;
      }
      // If 'custom' is selected, don't set fromDate and toDate
      default: {
        fromDate = filterData.from;
        toDate = filterData.to;
        break;
      }
    }

    setFilterData({
      ...filterData,
      from: fromDate,
      to: toDate
    });
  };

  const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleChangeGame = (event) => {
    const {
      target: { value },
    } = event;
    const type = typeof value === 'string' ? value.split(',') : value;
    setFilterData({ ...filterData, gameType: type });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const type = typeof value === 'string' ? value.split(',') : value;
    setFilterData({ ...filterData, status: type });
  };

  useEffect(() => {
    handleDateChange('today');
  }, [])

  return (
    <div className="fillterContainer">
      <Grid container spacing={3} className="fillterGrid">
        <Grid item xs={12} xl={4} sm={4}>
          <Label>Date</Label>
          <Select fullWidth label={'date'} value={date} defaultValue={"today"}
            onChange={(e) => handleDateChange(e.target.value)}>
            <MenuItem value={"today"}>Today</MenuItem>
            <MenuItem value={"yesterday"}>Yesterday</MenuItem>
            <MenuItem value={"this_week"}>This Week</MenuItem>
            <MenuItem value={"last_week"}>Last Week</MenuItem>
            <MenuItem value={"this_month"}>This Month</MenuItem>
            <MenuItem value={"last_month"}>Last Month</MenuItem>
            <MenuItem value={"custom"}>Custom</MenuItem>
          </Select>
        </Grid>
        {filterData.from !== null && filterData.to !== null && (
          <>
            <Grid item xs={12} xl={4} sm={4}>
              <Label>From</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem >
                  <DatePicker
                    value={filterData.from ? dayjs(filterData.from) : ''}
                    onChange={(date) => {
                      setDate('custom')
                      setFilterData({
                        ...filterData,
                        from: new Date(date)
                      })
                    }
                    }
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} xl={4} sm={4}>
              <Label>To</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem >
                  <DatePicker
                    value={filterData.to ? dayjs(filterData.to) : ''}
                    onChange={(date) => {
                      setDate('custom')
                      setFilterData({
                        ...filterData,
                        to: new Date(date)
                      })
                    }
                    }
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid>
          </>
        )}
        {/* <Grid item></Grid> */}
      </Grid>
      <Grid container spacing={3} className="fillterGrid">
        <Grid item xs={12} sm={6} xl={3}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-chip-label">Shop</InputLabel>
            <Select
              fullWidth
              value={filterData.shop}
              defaultValue={0}
              onChange={(e) => setFilterData({ ...filterData, shop: e.target.value })}
              input={<OutlinedInput id="select-multiple-chip" label="Shop" />}>
              <MenuItem value={0} disabled>Select shop</MenuItem>
              {
                shop.map((item, index) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))
              }
            </Select>

          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} xl={3} sx={{ maxWidth: '100%' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-name-label">Game Type</InputLabel>
            <Select
              labelId="demo-multiple-game-type-label"
              id="demo-multiple-game-type"
              multiple
              value={filterData.gameType}
              onChange={handleChangeGame}
              input={<OutlinedInput id="select-multiple-chip" label="Game type" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {gameType.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, filterData.gameType, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          {/* <Label>Status</Label> */}
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={filterData.status}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Status" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, filterData.status, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} xl={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {
            loader ? (
              <Button variant="contained" fullWidth style={{ height: '40px', padding: '10px', background: '#7FD858' }}><CircularProgress sx={{ color: 'white' }} /></Button>
            ) : (
              <SearchButton handleclick={handleFetchData} text={'Search'} />
            )
          }
        </Grid>
        {/* <Grid item></Grid> */}
      </Grid>
    </div>
  )
}

export default function SlipReport(params) {
  const { data, dispatch } = useContext(CampaignContext);
  const [owner, setOwner] = useState([]);
  const [shop, setShop] = useState([]);
  const [date, setDate] = useState('today');
  const [loader, setLoader] = useState(false);

  const today = new Date();
  const endOfDay = new Date(today);
  today.setHours(0, 0, 0, 0); // Set time to start of the day
  endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day

  const [filterData, setFilterData] = useState({
    from: today,
    to: endOfDay,
    shop: 0,
    gameType: [],
    status: []
  })

  const handleFetchShop = () => {
    getSubAgentShop(shop, setShop, dispatch, setLoader);
  }

  const handleFetchData = () => {
    // console.log('shop', shop);
    if (filterData.shop) {
      getSlipReport(owner, setOwner, dispatch, setLoader, filterData);
    } else {
      console.log('select a shop');
    }
  }

  useEffect(() => {
    handleFetchShop();
    handleFetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Slip Report </title>
      </Helmet>
      <Container maxWidth="xl" >
        <FilterTable filterData={filterData} setFilterData={setFilterData} handleFetchData={handleFetchData} loader={loader} shop={shop} />

        <SlipTable owner={owner} loader={loader} />

      </Container>
    </>
  );
};
