import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
//
import LoginPage from "./pages/LoginPage";
import RetailReport from "./pages/Report/RetailReport";
import ManageShop from "./pages/Manage/ManageShop";
import ManageCashier from "./pages/Manage/ManageCashier";
import SlipDetail from "./pages/Report/SlipDetail";
import GameResult from "./pages/Report/gameResult";
import CashierReport from "./pages/Report/CashierReport";
import SlipReport from "./pages/Report/SlipReport";
import FindPage from "./pages/Report/Find";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <RetailReport /> },
        // { path: "manageShop", element: <ManageShop /> },
        // { path: "manageCashier", element: <ManageCashier /> },
        { path: "cashierReport", element: <CashierReport /> },
        { path: "slipDetail", element: <SlipDetail /> },
        { path: "slipReport", element: <SlipReport /> },
        { path: "gameResult", element: <GameResult /> },
        { path: "findPage", element: <FindPage /> },
      ],
    },
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
